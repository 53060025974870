<script lang="ts" setup>
import type { OfferPreset } from "~/types";
const DECOR_LIST = ["percent-stars", "stars-percent"];

const { t } = useT();
const { open } = useAppModals();
const { data: appInitData } = useAppInitData();
const { handleSupportButtonClick } = useSupportChat();
const { questData } = useQuestTask();
const loginGuard = useLoginGuard();
const { isSweepStakes } = useSwitchMode();
const { tournamentData } = useRace();
const { entriesActiveTournament } = useTournamentNotification();
const { isEventTheme, isEventBox } = useEventsCollector();
const { isSupercharged, isRegular } = useMysteryBoxes();
const { depositInfoData } = useFunzcityDepositStreak();
const { seasonInitData } = useSeasonsData({ immediate: false });
const { isFreeSpinQuest } = useFreeSpinQuest();
const { prizeDropActive } = usePrizeDrop();

const asideRef = ref();

const props = withDefaults(
	defineProps<{
		show: boolean;
	}>(),
	{ show: false }
);

const emit = defineEmits<{ (event: "toggleSidebar"): void }>();

const bannerPromo = computed(() => appInitData.value?.popupPresetPackage as OfferPreset);

const { handleLogout } = useProfileData();
const { homePromotions } = usePromotionsData({ immediate: false });
const { promotionsData } = usePromotionsData({ immediate: false });
const { isAvailable, isActiveDailyLogin } = useDailyLogin();

const toggleShow = () => {
	emit("toggleSidebar");
};

onClickOutside(asideRef, () => {
	if (!props.show) {
		return;
	}
	emit("toggleSidebar");
});

const isGuest = computed(() => appInitData.value?.isGuest);

const hasDeposits = computed(() => appInitData.value?.hasDeposits);

const depositsPassed = computed(() => depositInfoData.value?.progress?.filter((deposit) => deposit)?.length);

const isShowRedeem = computed(() => {
	if (appInitData.value?.redeem?.show === false) {
		return false;
	}

	if (isSweepStakes.value) {
		return appInitData.value?.redeem?.isEntries;
	}

	return appInitData.value?.redeem?.isCoins;
});

const affiliatePartnerState = ref(false);
const isShowAffiliate = computed(() => appInitData.value?.isAffiliate && !isGuest.value);

const handleRedeemClick = () => {
	if (!hasDeposits.value) {
		open("LazyOModalRedeemHowItWork");

		return;
	}
	loginGuard({
		success: () => {
			window?.$store?.gaCash?.deposit?.({
				location: "redeem",
				step: "payments_method"
			});
			window?.$cash?.$router?.push?.("/cash/redeem/");
			emit("toggleSidebar");
		},
		fail: () => {
			open("LazyOModalSignup");
			dispatchGAEvent({
				event: "registration",
				location: "header",
				step: "start"
			});
			emit("toggleSidebar");
		}
	});
};

const navigateIfAuthorized = (event: MouseEvent) =>
	loginGuard({
		fail: () => {
			emit("toggleSidebar");
			open("LazyOModalSignup");
			dispatchGAEvent({
				event: "registration",
				location: "header",
				step: "start"
			});
			event.preventDefault();
			event.stopPropagation();
		}
	});

const handlePromotionsClick = navigateIfAuthorized;
const handleVipClick = navigateIfAuthorized;
const handleReferralClick = navigateIfAuthorized;
const handlePrizeDropsClick = navigateIfAuthorized;

const handleQuestsClick = () => {
	loginGuard({
		success: () => {
			emit("toggleSidebar");
			open("LazyOModalQuests");
		},
		fail: () => {
			emit("toggleSidebar");
			open("LazyOModalSignup");
			dispatchGAEvent({
				event: "registration",
				location: "header",
				step: "start"
			});
		}
	});
};

const handleFreeSpinClick = () => {
	loginGuard({
		success: () => {
			open("LazyOModalFreeSpin");
		},
		fail: () => {
			open("LazyOModalSignup");
			dispatchGAEvent({
				event: "registration",
				location: "header",
				step: "start"
			});
		}
	});
	emit("toggleSidebar");
};

const handleDepositStreakClick = () => {
	emit("toggleSidebar");
	loginGuard({
		success: () => {
			open("LazyOModalDepositStreak");
		},
		fail: () => {
			open("LazyOModalSignup");
			dispatchGAEvent({
				event: "registration",
				location: "header",
				step: "start"
			});
		}
	});
};

const handleMysteryBoxClick = () => {
	loginGuard({
		success: () => {
			emit("toggleSidebar");
			open("LazyOModalMysteryBox");
		},
		fail: () => {
			emit("toggleSidebar");
			open("LazyOModalSignup");
			dispatchGAEvent({
				event: "registration",
				location: "header",
				step: "start"
			});
		}
	});
};

const handleSupportClick = () => {
	dispatchGAEvent({
		event: "click_button",
		location: "left_menu",
		button_name: "support"
	});
	handleSupportButtonClick();
};

const handleDailyClick = () =>
	loginGuard({
		success: () => {
			emit("toggleSidebar");
			open("LazyOModalDailyLogin");

			dispatchGAEvent({
				event: "click_button",
				button_name: "daily_login_popup",
				location: "sidebar_menu"
			});
		},
		fail: () => {
			emit("toggleSidebar");
			open("LazyOModalSignup");
		}
	});

const handleOpenSeason = () =>
	loginGuard({
		success: () => {
			emit("toggleSidebar");
			open("LazyOModalSeason");

			dispatchGAEvent({
				event: "click_button",
				button_name: "season_popup",
				location: "sidebar_menu"
			});
		},
		fail: () => {
			emit("toggleSidebar");
			open("LazyOModalSignup", { location: "sidebar_season" });
		}
	});
</script>
<template>
	<AOverlay v-if="show" :modifiers="['fixed']" :class="{ show }">
		<Transition name="slide" data-tid="menu-container" appear>
			<aside ref="asideRef" data-tid="menu-container">
				<AIconButton class="close-btn" variant="ghost" data-tid="header-close" @click="toggleShow">
					<NuxtIcon name="24/close-green" filled />
					<AText :modifiers="['uppercase', 'semibold']">
						{{ t("close") }}
					</AText>
				</AIconButton>

				<div class="links-wrapper" data-tid="menu-links">
					<NuxtLink to="/" class="link text-tlalnepantla" data-tid="header-home">
						<AText variant="taiz" :modifiers="['bold']" class="text-coro">
							{{ t("Home") }}
						</AText>
					</NuxtLink>
					<NuxtLink to="/issues/all-games/" class="link text-tlalnepantla" data-tid="header-all-games">
						<AText variant="taiz" :modifiers="['bold']" class="text-coro">
							{{ t("Games") }}
						</AText>
					</NuxtLink>
					<NuxtLink
						to="/promotions/"
						class="link text-tlalnepantla"
						data-tid="header-promotions"
						@click.capture="handlePromotionsClick"
					>
						<AText variant="taiz" :modifiers="['bold']" class="text-coro">
							{{ t("Promotions") }}
						</AText>
						<AText as="sup" variant="taipei" :modifiers="['bold']" class="counter tlalnepantla">
							{{ homePromotions?.promotions?.length || promotionsData?.promotions?.length }}
						</AText>
					</NuxtLink>
					<AButton v-if="seasonInitData?.isEnabled" class="nav-button text-tlalnepantla" @click="handleOpenSeason">
						<AText variant="taiz" :modifiers="['bold']" class="text-coro">
							{{ t("Power Tower") }}
						</AText>
						<AText
							v-if="!seasonInitData?.isAvailable"
							as="sup"
							variant="taipei"
							:modifiers="['bold']"
							class="counter tlalnepantla"
						>
							1
						</AText>
					</AButton>
					<NuxtLink
						v-if="tournamentData?.isActiveStatus"
						to="/races/"
						class="link text-tlalnepantla"
						data-tid="header-races"
						@click.capture="navigateIfAuthorized"
					>
						<AText variant="taiz" :modifiers="['bold']" class="text-coro">
							{{ t("Races") }}
						</AText>
						<AText
							v-if="entriesActiveTournament"
							as="sup"
							variant="taipei"
							:modifiers="['bold']"
							class="counter tlalnepantla"
						>
							1
						</AText>
					</NuxtLink>
					<AButton
						v-if="!isGuest && isFreeSpinQuest"
						class="nav-button text-tlalnepantla"
						data-tid="header-daily-missions"
						@click="handleFreeSpinClick"
					>
						<NuxtImg
							class="free-spin-badge"
							src="/nuxt-img/free-spin/aside-badge.png"
							width="189"
							height="57"
							loading="lazy"
							format="avif"
							alt="free-spin-badge"
						/>
						<AText variant="taiz" :modifiers="['bold']" class="text-coro">
							{{ t("freeSpin.asideBar.link.name") }}
						</AText>
					</AButton>
					<NuxtLink
						v-if="!isGuest && appInitData?.scratchCardLottery?.isActive"
						to="/scratch-cards/"
						class="link text-tlalnepantla"
					>
						<AText variant="taiz" :modifiers="['bold']" class="text-coro">
							{{ t("scratchCards.sidebar.link.name") }}
						</AText>
					</NuxtLink>
					<AButton
						v-if="!isGuest && !!appInitData?.depositStreak?.isActive"
						class="nav-button text-tlalnepantla"
						@click="handleDepositStreakClick"
					>
						<AText variant="taiz" :modifiers="['bold']" class="text-coro">
							{{ t("depositStreak.name") }}
						</AText>
						<AText
							v-if="!depositInfoData?.dailyWinStreakLimitExceeded"
							as="sup"
							variant="taipei"
							:modifiers="['bold']"
							class="counter tlalnepantla"
						>
							{{ `${depositsPassed}/${depositInfoData?.maxNumberOfDeposit}` }}
						</AText>
					</AButton>
					<AButton
						v-if="!isFreeSpinQuest && !!questData?.data?.dayInfo"
						class="nav-button text-tlalnepantla"
						data-tid="header-daily-missions"
						@click="handleQuestsClick"
					>
						<AText variant="taiz" :modifiers="['bold']" class="text-coro">
							{{ t("Daily Missions") }}
						</AText>
					</AButton>
					<AButton
						v-if="isActiveDailyLogin || (appInitData?.dailyLogin?.isActive && isGuest)"
						class="nav-button text-tlalnepantla"
						@click="handleDailyClick"
					>
						<AText variant="taiz" :modifiers="['bold']" class="text-coro">
							{{ t("Daily Gift") }}
						</AText>
						<NuxtImg
							v-if="isAvailable || isGuest"
							src="/nuxt-img/daily-login/prize.png"
							class="gift"
							width="68"
							height="68"
							alt="gift"
							format="avif"
							loading="lazy"
						/>
					</AButton>
					<AButton
						v-else
						class="nav-button text-tlalnepantla"
						data-tid="header-mystery-box"
						@click="handleMysteryBoxClick"
					>
						<NuxtImg
							v-if="isEventBox"
							class="lucky-roulette-badge"
							src="/nuxt-img/lucky-roulette/aside-badge.png"
							width="81"
							height="32"
							format="webp"
							qiality="100"
							alt="Lucky-roulette-badge"
						/>
						<NuxtImg
							v-else-if="isSupercharged || isRegular"
							:src="`/nuxt-img/mystery-box/label-${isRegular ? 'regular' : 'super'}.png`"
							:class="['super-box', { regular: isRegular }]"
							:width="isRegular ? '286' : '414'"
							height="114"
							:alt="`label ${isRegular ? 'regular' : 'super'} box`"
							format="avif"
							loading="lazy"
						/>
						<AText variant="taiz" :modifiers="['bold']" class="text-coro">
							{{ isEventBox ? t("Dice Dash") : t("Mystery Box") }}
						</AText>
					</AButton>
					<NuxtLink
						v-if="prizeDropActive"
						to="/prize-drops/"
						class="link text-tlalnepantla"
						data-tid="header-prize-drops"
						@click.capture="handlePrizeDropsClick"
					>
						<AText variant="taiz" :modifiers="['bold']" class="text-coro">
							{{ t("Mad Drops") }}
						</AText>
					</NuxtLink>
					<NuxtLink
						to="/vip-club/"
						class="link text-tlalnepantla"
						data-tid="header-vip-club"
						@click.capture="handleVipClick"
					>
						<AText variant="taiz" :modifiers="['bold']" class="text-coro">
							{{ t("VIP Club") }}
						</AText>
					</NuxtLink>
					<NuxtLink v-if="!isGuest" to="/game/" class="link text-tlalnepantla" data-tid="header-account">
						<AText variant="taiz" :modifiers="['bold']" class="text-coro">
							{{ t("Account") }}
						</AText>
					</NuxtLink>
					<NuxtLink
						to="/referral/"
						class="link text-tlalnepantla"
						data-tid="header-referral"
						@click.capture="handleReferralClick"
					>
						<AText variant="taiz" :modifiers="['bold']" class="text-coro">
							{{ t("Invite friends") }}
						</AText>
					</NuxtLink>
					<div v-if="isShowRedeem" class="nav-link" data-tid="header-redeem" @click="handleRedeemClick">
						<AText variant="toledo" :modifiers="['underline', 'medium']" class="text-cannes">
							{{ t("Redeem") }}
						</AText>
					</div>

					<NuxtLink v-if="!isGuest" to="/how-it-works/" class="nav-link" data-tid="header-how-it-works">
						<AText variant="toledo" :modifiers="['underline', 'medium']" class="text-cannes">
							{{ t("How to play?") }}
						</AText>
					</NuxtLink>
					<div class="nav-link" data-tid="header-support" @click="handleSupportClick">
						<AText variant="toledo" :modifiers="['underline', 'medium']" class="text-cannes">
							{{ t("Support") }}
						</AText>
					</div>

					<template v-if="!isGuest">
						<NuxtLink v-if="!isShowAffiliate" to="/affiliates/" class="nav-link" data-tid="header-affiliate-program">
							<AText variant="toledo" :modifiers="['underline', 'medium']" class="text-cannes">
								{{ t("Affiliate Program") }}
							</AText>
						</NuxtLink>
						<AText
							v-else
							:class="[{ 'is-disabled': affiliatePartnerState }]"
							variant="toledo"
							:modifiers="['underline', 'medium']"
							class="text-cannes"
							@click="affiliatePartnerState = !affiliatePartnerState"
						>
							{{ t("Affiliate Program") }}

							<NuxtIcon
								class="arrow-icon arrow-icon-affiliate-partner"
								:name="`16/${affiliatePartnerState ? 'arrow-up-small' : 'arrow-down-small'}`"
								filled
							/>
						</AText>
						<Transition name="fade">
							<NuxtLink
								v-if="affiliatePartnerState"
								to="/affiliates/partners-account"
								data-tid="header-affiliate-account"
								class="nav-link nav-link-affiliate-partner"
							>
								<AText variant="toledo" :modifiers="['underline', 'medium']" class="text-cannes">
									{{ t("Partner’s Account") }}
								</AText>
							</NuxtLink>
						</Transition>
					</template>

					<div v-if="!isGuest" class="nav-link" data-tid="header-logout" @click="handleLogout">
						<AText variant="toledo" :modifiers="['underline', 'medium']" class="text-cannes">
							{{ t("Log Out") }}
						</AText>
					</div>

					<template v-if="isEventTheme">
						<NuxtImg
							v-for="decorItem in DECOR_LIST"
							:key="decorItem"
							:src="`/nuxt-img/event-decor/${decorItem}.svg`"
							:class="['event-icon', decorItem, { guest: isGuest }]"
							width="440"
							height="440"
							:alt="decorItem"
							loading="lazy"
						/>
					</template>
				</div>

				<OHomeBannerPromotionItem
					v-if="bannerPromo?.promoOfferPreset?.bestDeal || bannerPromo?.promoOfferPreset?.mostPopular"
					:event-decor="true"
					class="promo-banner"
					data-tid="header-promo-banner"
					:offer="bannerPromo"
				/>
			</aside>
		</Transition>
	</AOverlay>
</template>
<style scoped lang="scss">
.overlay {
	z-index: 100;
	display: none;
	width: 100%;

	&.show {
		display: block;
	}
}
aside {
	background: var(--goiania);
	width: var(--o-sidebar-width);
	max-width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: gutter(3);
	padding: gutter(3) gutter(2);
	overflow: auto;
	position: relative;

	a {
		display: inline-flex;
		text-decoration: none;
	}
}

.close-btn {
	display: flex;
	justify-content: flex-start;
	align-items: baseline;
	gap: gutter(1);
	& > * {
		color: var(--сirebon);
	}

	.nuxt-icon {
		font-size: 24px;

		&:deep(svg) {
			margin-bottom: 0;
		}
	}
}

.links-wrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: gutter(1.1);
	margin-top: gutter(0.375);
}

.link {
	display: flex;
	align-items: center;
	gap: gutter(1.125);
	position: relative;
	z-index: 1;
	cursor: pointer;
	width: 100%;
	line-height: 19.04px;

	&:hover,
	&.router-link-active {
		& > * {
			color: var(--coyoacan);
		}
	}

	.counter {
		color: var(--сirebon);
	}
}

.nav-button {
	padding: 0;
	border: none;
	justify-content: flex-start;
	position: relative;
	overflow: initial;
	box-shadow: none;

	&:hover {
		background: transparent;

		span {
			color: var(--coyoacan);
		}
	}

	.lucky-roulette-badge {
		position: absolute;
		top: -10px;
		right: 105px;
	}

	.free-spin-badge {
		position: absolute;
		top: -10px;
		right: 70px;
		height: auto;
		width: 124px;
	}

	.counter {
		color: var(--сirebon);
	}
}

.nav-link {
	margin-top: 10px;
	&:first-child {
		margin-top: 25px;
	}
	&:hover,
	&.router-link-active {
		& > * {
			text-decoration: none;
		}
	}
}

.nav-link-affiliate-partner {
	padding-left: 24px;
}

.arrow-icon-affiliate-partner {
	&:deep(svg) {
		font-size: 20px;
		color: var(--clara);
	}

	&:deep(path) {
		stroke: var(--clara);
	}
}

.nuxt-icon {
	font-size: 20px;

	&:deep(svg) {
		transition: 0.2s ease;
	}
}

.promo-banner {
	margin-top: auto;
}

$transition: all 250ms ease-in-out;
.fade-enter-active,
.fade-leave-active {
	transition: $transition;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

.slide-enter-active {
	transition: $transition;
	transform: translateX(-100%);
}
.slide-enter-to {
	transform: translateX(0%);
}
.slide-leave-active {
	transition: $transition;
	transform: translateX(0%);
}
.slide-leave-to {
	transform: translateX(-100%);
}

.gift {
	width: 38px;
	height: 38px;
	margin-left: 4px;
}

.super-box {
	position: absolute;
	right: 30px;
	top: -7px;
	width: 130px;
	height: 36px;
	transform: rotate(12deg);

	&.regular {
		width: 92px;
		right: 68px;
		top: -12px;
	}
}

.event-icon {
	position: absolute;

	&.hat {
		top: -70px;
		left: 130px;
		width: 92px;
		height: 113px;
	}

	&.jar {
		right: 23px;
		top: 17px;
		width: 85px;
		height: 85px;
	}

	&.spider {
		right: 20px;
		bottom: 0;
		width: 114px;
		height: 102px;

		&.guest {
			bottom: -140px;
		}
	}

	&.stars-percent {
		right: 10px;
		top: -30px;
		width: 134px;
		height: 78px;
		object-fit: cover;
	}
	&.percent-stars {
		right: 10px;
		bottom: 0;
		width: 95px;
		height: 54px;
		object-fit: cover;
		@include media-breakpoint-down(sm) {
			bottom: -30px;
		}
	}
}
</style>
